<template>
  <img :src="imageSrc"
       :alt="modifiedCollegeName"
       @error="onImageError"
       :class="className"
       :style="computedImgStyle" />
</template>

<script>
export default {
  name: 'CollegeImage',
  props: {
    collegeName: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    imgStyle: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      imageStatus: {},
      imageSrc: '',
      modifiedCollegeName: this.collegeName  // Create a local copy of collegeName
    };
  },
  computed: {
    computedImgStyle() {
      return this.className ? {} : this.imgStyle;
    }
  },
  methods: {
    checkAllImages() {
      // Modify the local copy instead of the prop
      if (this.modifiedCollegeName.includes('ATU')) {
        this.modifiedCollegeName = 'Atlantic Technological University - ATU (Galway)';
      }
      else if (this.modifiedCollegeName.includes('TUS')) {
        this.modifiedCollegeName = 'Technological University of the Shannon - TUS (Athlone)';
      }
      else if (this.modifiedCollegeName.includes('MTU')) {
        this.modifiedCollegeName = 'Munster Technological University - MTU (Cork)';
      }

      // Start by checking the .svg path
      this.imageSrc = `${this.publicPath}img/colleges/${this.modifiedCollegeName}.svg`;
      this.checkImage(this.imageSrc, 'svg');
    },

    checkImage(path, type) {
      const img = new Image();
      img.onload = () => {
        this.$set(this.imageStatus, path, true);
        this.imageSrc = path;
      };
      img.onerror = () => {
        this.$set(this.imageStatus, path, false);
        if (type === 'svg') {
          const pngPath = `${this.publicPath}img/colleges/${this.modifiedCollegeName}.png`;
          this.checkImage(pngPath, 'png');
        } else {
          this.imageSrc = `${this.publicPath}img/sv-logo.jpg`;
        }
      };
      img.src = path;
    },

    onImageError() {
      this.imageSrc = `${this.publicPath}img/sv-logo.jpg`;
    }
  },

  mounted() {
    this.checkAllImages();  // Initial check when the component is mounted
  }
};
</script>
